<template>
  <!--begin::Legal Terms ES-->
  <div class="p-6">
    <div class="text-center h1-primary mt-3 mb-6">
      Condiciones generales de uso y contratación
      <div class="tertiary-rectangle" style="margin: 0.25em auto;"></div>
    </div>

    <div class="paragraph-primary">
      Estas condiciones generales de uso y contratación 
      (en adelante, <strong>Condiciones Generales de Uso y Contratación</strong>) 
      se aplican al sitio web de la  entidad: (LOGIOK 4 PUNTO 0, S.L.), 
      en adelante (LOGIOK) cuyo dominio es (https://logiok.es) 
      y a la correspondiente aplicación móvil.  
      El sitio es propiedad de (LOGIOK 4 PUNTO 0, S.L.). 
      Al utilizar este sitio, muestras tu conformidad y expresa aceptación a las presentes condiciones de uso. 
      Si no estás de acuerdo, te rogamos te abstengas de utilizarlo.
    </div>
    
    <div class="paragraph-primary mt-3">
      Dichas condiciones generales de uso y contratación sirven para poner a disposición de los usuarios, 
      el sitio web y la aplicación móvil.
    </div>
    
    <div class="paragraph-primary mt-3">
      En cumplimiento de lo establecido en la normativa reguladora 
      (Ley de servicios de sociedad de la información) 
      se exponen los siguientes datos identificativos del titular del sitio: 
    </div>
      
    <ul class="ml-9 my-6">
      <li>Denominación social: <strong>LOGIOK 4 PUNTO 0, S.L.</strong></li>
      <li>Domicilio social: <strong>Rambla Catalunya, 124 - PLANTA 3 PUERTA 1, Barcelona, 08008, Barcelona.</strong></li>
      <li>Datos de inscripción en el Registro Mercantil: <strong>???</strong></li>
      <li>Número de identificación fiscal: <strong>B67459305</strong></li>
      <li>Correo electrónico de contacto: <a href="mailto:info@logiok.es"><strong><u>info@logiok.es</u></strong></a></li>
      <li>Número de teléfono de contacto: <strong>687 896 342</strong></li>
    </ul>
    
    <div class="h2-primary mt-9 mb-6">
      1.- Objeto
    </div>

    <div class="paragraph-primary">
      LOGIOK es una compañía cuya actividad principal es el desarrollo y gestión de una plataforma tecnológica 
      que permite a determinados proveedores cuya actividad consiste en el transporte de vehículos 
      ofertar sus servicios a través de la misma, y en su caso, 
      si los Usuarios de la APP y solicitantes de los citados servicios así lo requieren a través de la APP, 
      de forma accesoria intermedia en la prestación inmediata o planificada de los servicios solicitados 
      por el Usuario.
    </div>
      
    <div class="paragraph-primary mt-3">
       LOGIOK ha desarrollado una Plataforma mediante la cual diferentes profesionales del transporte, 
       con los que LOGIOK puede mantener un acuerdo comercial por el uso de la plataforma, 
       ofrecen una serie de servicios. 
       El Usuario tiene la posibilidad de solicitar la prestación de servicios de estos profesionales 
       mediante el mandato que confiere a un tercero al solicitar un servicio a través de la Plataforma, 
       en cuyos casos LOGIOK actúa como mero intermediario y, por lo tanto, 
       no puede asumir ni asume responsabilidad alguna sobre la calidad del servicio 
       o la correcta prestación de los servicios ofrecidos directamente por los proveedores ni por terceros.   
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      2.-  Términos de Uso
    </div>
      
    <div class="paragraph-primary">
      El acceso a la Plataforma, y la creación voluntaria de un perfil por parte del Usuario, 
      implica el conocimiento y la aceptación expresa e inequívoca de 
      las presentes <strong>Condiciones Generales de Uso y Contratación</strong>
      y la <router-link to="/legal/privacy"><strong><u>Política de Privacidad</u></strong></router-link>
      y la <router-link to="/legal/cookies"><strong><u>Política de Cookies</u></strong></router-link>
      por parte de todos los Usuarios.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      3.- Acceso y Registro para Usuarios
    </div>
      
    <div class="paragraph-primary">
      Para poder ser Usuario de la Plataforma es indispensable que se cumplan los siguientes requisitos:
    </div>
    
    <ul class="ml-9 my-6">
      <li>
        Haber cumplido o ser mayor de 18 años de edad.
      </li>
      <li>
        Cumplimentar de manera veraz los campos obligatorios del formulario de registro, 
        en el que se solicitan los siguientes datos de carácter personal: 
        Nombre y apellidos, CIF/NIF/NIE, dirección postal completa, número de teléfono y correo electrónico.
      </li>
      <li>
        Aceptar las presentes <strong>Condiciones Generales de Uso y Contratación</strong>.
      </li>
      <li>
        Aceptar la <router-link to="/legal/privacy"><strong><u>Política de Privacidad y Protección de Datos</u>.</strong></router-link>
      </li>
      <li>
        Aceptar la <router-link to="/legal/cookies"><strong><u>Política de Cookies.</u></strong></router-link>
      </li>
    </ul>
      
    <div class="paragraph-primary mt-3">
      El Usuario garantiza que todos los datos sobre su identidad y legitimidad 
      facilitados a LOGIOK en sus formularios de registro de la Plataforma son veraces, 
      exactos y completos. Asimismo, se compromete a mantener actualizados sus datos.    
    </div>
    
    <div class="paragraph-primary mt-3">
      En el supuesto en que el Usuario facilite cualquier dato falso, inexacto o incompleto 
      o si LOGIOK considera que existen motivos fundados para dudar sobre la veracidad, 
      exactitud e integridad de los mismos, LOGIOK podrá denegarle el acceso y uso 
      presente o futuro de la Plataforma o de cualquiera de sus contenidos y/o servicios.   
    </div>

    <div class="paragraph-primary mt-3">
       Al darse de alta en la Plataforma, el Usuario seleccionará un nombre de Usuario (username) 
       y una clave de acceso (password). Tanto el username como el password son estrictamente confidenciales, 
       personales e intransferibles. 
       LOGIOK recomienda no utilizar las mismas credenciales de acceso que en otras plataformas 
       para aumentar la seguridad de las cuentas. 
       En el supuesto que el Usuario utilice las mismas credenciales de acceso que en otras plataformas, 
       LOGIOK no podrá garantizar la seguridad de la cuenta ni asegurar que el Usuario es el único que accede a su perfil.   
    </div>
      
    <div class="paragraph-primary mt-3">
      El Usuario se compromete a no divulgar los datos relativos a su cuenta ni hacerlos accesibles a terceros. 
      El Usuario será el único responsable en caso de uso de dichos datos o de los servicios del Sitio por terceros, 
      incluidas las manifestaciones y/o contenidos vertidos en la Plataforma, 
      o cualquier otra actuación que se lleve a cabo mediante el uso del username y/o password.    
    </div>
      
    <div class="paragraph-primary mt-3">
       El Usuario será el único propietario sobre el contenido vertido por el mismo en la Plataforma. 
       Asimismo, a través del registro en la Plataforma y la aceptación de las presentes Condiciones, 
       el Usuario concede a LOGIOK, en relación con el contenido que pueda proveer, 
       una licencia mundial, irrevocable, transferible, gratuita, con derecho a sublicencia, 
       uso, copia, modificación, creación de obras derivadas, distribución, comunicación pública 
       así como su explotación en cualquier forma que LOGIOK considere apropiada, 
       con o sin comunicación ulterior al Usuario 
       y sin la necesidad de liquidar cantidad alguna por los mencionados usos.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no puede garantizar la identidad de los Usuarios registrados, por lo tanto, 
      no será responsable del uso de la identidad de un Usuario registrado por terceros no registrados. 
      Los Usuarios se obligan a poner inmediatamente en conocimiento de LOGIOK la sustracción, 
      divulgación o pérdida de sus credenciales, comunicándolo a través de los canales de comunicación 
      ofrecidos por LOGIOK.
    </div>
      
    <div class="h3-primary mt-9 mb-6">
      3.1.-Perfil
    </div>
      
    <div class="paragraph-primary">
      Para poder completar el registro en la Plataforma el Usuario deberá proporcionar 
      los siguientes datos personales: Nombre y apellidos, CIF/NIF/NIE, dirección postal completa, 
      número de teléfono y correo electrónico. 
      Para llevar a cabo el registro será necesario que nos autorices al tratamiento de tus datos personales 
      con la estricta finalidad de gestionar tu actividad en la plataforma. 
      Una vez completado el registro, todo Usuario podrá acceder a su perfil y completarlo, 
      editarlo según estime conveniente. 
      LOGIOK no almacena los datos de pago de los usuarios, que serán tratados y almacenados 
      por el prestador de servicios de pago que cumpla con las garantías necesarias 
      para llevar a cabo un pago seguro. 
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      4.- Funcionamiento del servicio
    </div>
      
    <div class="paragraph-primary">
      El Usuario es el único responsable en la correcta consignación de las direcciones de entrega y recogida 
      en la Plataforma, por lo que exime a LOGIOK y al proveedor de cualquier negligencia o error 
      en la recogida o entrega del pedido derivada de la consignación errónea de las direcciones de entrega y recogida. 
      Como consecuencia de ello, será el Usuario quien deba asumir el coste derivado 
      de la incorrecta consignación de las direcciones de entrega y recogida en la Plataforma.
    </div>
      
    <div class="paragraph-primary mt-3">
      El Usuario deberá proporcionar toda la información, lo más detallada posible, respecto al servicio requerido. 
      El Usuario tendrá comunicación constante con el proveedor, pudiéndose dirigirse a él en todo momento, 
      a los efectos de que éste ejecute el servicio conferido según las propias indicaciones del Usuario.
    </div>
      
    <div class="paragraph-primary mt-3">
      A efectos de facilitar la comunicación directa con el proveedor 
      y en el supuesto que exista alguna incidencia en la tramitación del encargo 
      y/o para comunicar algún cambio en el mismo, LOGIOK pone a disposición de los Usuarios 
      un medio de comunicación directo que permitirá el contacto directo e inmediato entre Usuario 
      y proveedor durante la ejecución del servicio.
    </div>
      
    <div class="paragraph-primary mt-3">
       El Usuario exime a LOGIOK de cualquier negligencia o error en las indicaciones 
       que consigne para la prestación del servicio que solicite en su nombre. 
       Como consecuencia de ello, será el Usuario quien deba asumir el coste derivado 
       de la incorrecta consignación de las indicaciones del servicio en la plataforma 
       (i.e. dirección incorrecta, características del transporte erróneas...).
    </div>
      
    <div class="paragraph-primary mt-3">
      En el caso de que el servicio de entrega no pueda completarse por causas imputables al Usuario, 
      esta parte deberá asumir el coste total (impuestos incluidos) de la tarifa de envío 
      (incluyendo la devolución del transporte al punto de recogida), más los eventuales recargos.
    </div>
      
    <div class="paragraph-primary mt-3">
      El Usuario, destinatario del servicio identificado en la solicitud del servicio, 
      o cualquier tercero autorizado por el mismo, deberá ratificar, en su caso, 
      el servicio conferido mediante su firma en el medio proporcionado por el proveedor. 
      En el supuesto que el Usuario reciba confirmación de la realización del servicio 
      sin haber procedido a la ratificación del transporte por sí mismo o mediante un receptor autorizado por éste, 
      deberá comunicarlo a LOGIOK de forma inmediata para que la Plataforma pueda tomar las medidas oportunas. 
      LOGIOK se reserva el derecho a comprobar las comunicaciones realizadas por el Usuario 
      a efectos de su comprobación y control.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      5.- Tarifas de servicios y facturación
    </div>
      
    <div class="paragraph-primary">
      El alta y uso de la Plataforma para los Usuarios tendrá coste dependiendo 
      de la modalidad escogida por el Usuario (existe registro gratuito, 
      en el se abonará por el Usuario un % de los gastos de cada servicio concertado, 
      y registro premium de abono periódico en el que no se cargará porcentaje en este concepto), 
      todos los precios y gastos a abonar serán objeto de información clara y concisa, 
      pudiendo estos ser revisados por parte de LOGIOK en cualquier momento. 
      Determinados servicios de la Plataforma pueden tener un coste para el Usuario, 
      según estos <strong>Términos y Condiciones Generales de Uso</strong>.
    </div>
      
    <div class="paragraph-primary mt-3">
      El uso de la Plataforma por parte de los proveedores no tendrá ningún coste por el alta en la plataforma, 
      pero se descontará una comisión respecto a su tarifa publicada en cada transporte consumado.
    </div>
      
    <div class="paragraph-primary mt-3">
      <strong>El precio total de cada servicio será determinado por el proveedor</strong>, 
      LOGIOK <strong>cobrará una cantidad en concepto de gastos de gestión</strong>. 
      De acuerdo con estas condiciones, el Usuario tendrá derecho a saber la tarifa de su servicio 
      antes de la contratación del mismo y formalización del pago, 
      exceptuando cuando el Usuario no especifique la dirección de recogida. 
    </div>
    
    <div class="paragraph-primary mt-3">
       LOGIOK se reserva el derecho de modificar precios en la plataforma en cualquier momento. 
       Los cambios efectuados tendrán efecto inmediato tras su publicación. 
       El Usuario autoriza expresamente a LOGIOK para que le remita por medios telemáticos, 
       a la dirección de correo electrónico facilitada por el mismo durante el proceso de registro, 
       el recibo de los servicios contratados y/o las facturas generadas. 
       En caso de requerir una factura, el Usuario deberá añadir los datos fiscales 
       a la plataforma antes de hacer el pedido.
    </div>
    
    <div class="paragraph-primary mt-3">
      La cancelación posterior del servicio por el Usuario, 
      cuando ya haya habido confirmación de preparación del pedido por parte del proveedor 
      y la misma haya sido comunicada al Usuario, facultará a LOGIOK a cargar al Usuario la tarifa que, 
      en su caso, corresponda. 
      Todo ello sin perjuicio de que el Usuario pueda solicitar un nuevo servicio 
      a los efectos de que se concrete el servicio en otra dirección. 
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      6.- Plataformas de pago
    </div>
      
    <div class="paragraph-primary">
      El pago de los servicios realizado por los Usuarios se recibe en las cuentas de LOGIOK 
      mediante una Entidad de Dinero Electrónico. 
      Las Entidades de Dinero Electrónico están autorizadas para proporcionar, directa o indirectamente, 
      servicios de pago regulados en todos los territorios en los que opera LOGIOK 
      y cumplen con la normativa vigente en materia de servicios de pago.
    </div>
      
    <div class="paragraph-primary mt-3">
      * En caso de no estar autorizadas dichas entidades, (LOGIOK) se exime de toda responsabilidad 
      por dicha falta de autorización o licencia, siendo única y exclusivamente responsabilidad 
      de las Entidades de Dinero Electrónico.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK, a través del proveedor de pagos que a tal efecto tiene contratado y 
      con el único objeto de comprobar el medio de pago facilitado, se reserva el derecho, 
      como medida antifraude, a solicitar la preautorización de cargo de los productos encargados 
      a través de la plataforma. 
      La citada preautorización no supondrá, en ningún caso, el pago total del encargo 
      en tanto éste se realizará única y exclusivamente tras la puesta a disposición de los productos 
      al Usuario o bien, por las causas descritas en los presentes términos y condiciones.
    </div>

    <div class="paragraph-primary mt-3">
      LOGIOK, actuará como primer punto de contacto con usuario y proveedor 
      y asumirá la responsabilidad por los pagos realizados a través de la Plataforma. 
      Esta responsabilidad incluye: reembolsos, devoluciones, cancelaciones y disputas de resolución, 
      en un estadio inicial, sin perjuicio de las actuaciones que LOGIOK pueda realizar 
      ante los proveedores de los servicios ordenados por los Usuarios.
    </div>

    <div class="paragraph-primary mt-3">
      Siguiendo cuanto descrito, en el supuesto que exista alguna disputa, 
      LOGIOK ofrecerá la primera línea de soporte y reembolsará al Usuario si se considera apropiado.
    </div>

    <div class="paragraph-primary mt-3">
      Si el Usuario tiene algún problema con el desarrollo del servicio contratado, 
      podrá contactar al servicio de atención al Usuario de LOGIOK 
      a través de los medios puestos a disposición del mismo en la Plataforma.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      7.- Precio de los servicios consignado en la Plataforma
    </div>
      
    <div class="paragraph-primary">
       Todos los precios que se indican en la Plataforma incluyen los impuestos 
       que pudieran ser aplicables en función del territorio desde el que opere el Usuario 
       y en todo caso se expresarán en la moneda vigente 
       en función del territorio desde el que opere el Usuario.   
    </div>
      
    <div class="paragraph-primary mt-3">
      De acuerdo con la Cláusula anterior, los precios aplicables en cada servicio 
      serán los publicados en la Plataforma sujetos a las particularidades expuestas 
      y aplicados de forma automática en el proceso de contratación en la última fase del mismo.
    </div>
      
    <div class="paragraph-primary mt-3">
      En todo caso, los precios corresponden a los servicios prestados por los proveedores 
      y son exclusivamente fijados por ellos. 
      El Usuario podrá comunicarse con el proveedor a los efectos de confirmar 
      el precio final de los servicios solicitados.
    </div>
      
    <div class="paragraph-primary mt-3">
      De acuerdo con lo anterior, el Usuario mediante la solicitud de servicio 
      a través de la Plataforma confiere al proveedor un mandato para portar 
      el bien objeto de transporte. 
      El Usuario podrá estar en contacto directo con el proveedor durante el desarrollo del servicio.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      8.- Derecho de desistimiento y cancelación de pedidos
    </div>
      
    <div class="paragraph-primary">
      De acuerdo con la propia naturaleza del servicio que ofrece LOGIOK, 
      el Usuario es consciente de que una vez un proveedor haya aceptado voluntariamente un pedido, 
      se considera que la ejecución del servicio ha comenzado 
      y por tanto el Usuario podría no tener derecho a desistir en la solicitud del servicio de forma gratuita.
    </div>
      
    <div class="paragraph-primary mt-3">
      Sin perjuicio de lo anterior, los costes de cancelación podrían depender de los siguientes factores:
    </div>
      
    <div class="paragraph-primary mt-3">
      - Si el Usuario cancela el pedido una vez el proveedor ha aceptado su tramitación, 
      se cobrará el importe de la tasa de cancelación al Usuario. 
      Se comunicará al Usuario la aceptación por parte del proveedor a través de la Plataforma. 
      El Usuario puede ver el coste de la cancelación en la Plataforma.
    </div>
      
    <div class="paragraph-primary mt-3">
      Los costes de cancelación totales aparecerán en la pantalla del Usuario 
      en el momento de apretar el botón "Cancelar" presente en la Plataforma. 
      De forma previa, se mostrará al Usuario el cargo que se aplicará 
      según los factores anteriormente descritos.
    </div>
      
    <div class="paragraph-primary mt-3">
      En el caso que el Usuario haya indicado de forma incorrecta la dirección de entrega de los productos, 
      podrá consignar una nueva dirección en cualquier momento 
      siempre que se encuentre dentro de la misma ciudad del encargo inicial 
      y siempre y cuando esta sea una ciudad dónde LOGIOK ofrece su servicio de intermediación. 
      En ese caso, el Usuario estará encargando la contratación de un nuevo servicio 
      y acepta que se le carguen los importes correspondientes a la nueva entrega. 
      En caso de encontrarse en otra ciudad de la inicialmente indicada, 
      ésta no podrá modificarse para entregarse en una nueva ciudad y el encargo se cancelará, 
      debiendo el Usuario asumir los costes generados según se establece en esta cláusula.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK se reserva el derecho a cancelar un pedido sin necesidad de alegar causa justa. 
      En caso de cancelación instada por LOGIOK sin causa justa, el Usuario tendrá derecho 
      al reembolso de la cantidad abonada.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK dispone de hojas de reclamación oficiales a disposición del Usuario, 
      por el objeto del servicio ofrecido por LOGIOK, en los idiomas oficiales de los países dónde LOGIOK opera. 
      Las mencionadas hojas de reclamación podrán solicitarse por parte del Usuario 
      a través del servicio de atención al Usuario de LOGIOK y será remitida de forma automática 
      la opción para acceder a las mismas. 
      El Usuario deberá especificar en el correo electrónico y la ubicación exacta 
      desde la que realiza la solicitud que deberá coincidir con el lugar de realización del servicio, 
      en caso de duda, será este último el lugar dónde deberá instarse la reclamación.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      9.- Entregas
    </div>
      
    <div class="paragraph-primary">
      LOGIOK proporciona al Usuario un servicio de entrega en determinadas ciudades. 
      El pedido se podrá realizar siempre que se cumplan las siguientes condiciones 
      en el momento de pago del servicio:  
    </div>
      
    <ul class="ml-9 my-6">
      <li>
        El servicio se encuentre operativo para la franja horaria escogida.
      </li>
      <li>
        La mercancía a entregar debe encontrarse dentro de alguna de las ciudades 
        y dentro del espacio habilitado en las que opere LOGIOK.
      </li>
      <li>
        El tamaño del pedido no puede superar las medidas indicadas por el proveedor.
      </li>
      <li>
        El peso máximo a cargar en un pedido será, el indicado por el proveedor.
      </li>
      <li>
        Que el transporte no suponga un ilícito en cualquier normativa aplicable, 
        quedando la responsabilidad derivada de este punto para usuario y/o proveedor 
        por la omisión de dicha normativa. 
      </li>
    </ul>
    
    <div class="h2-primary mt-9 mb-6">
      10.- Obligaciones del Usuario
    </div>
      
    <div class="paragraph-primary">
      Los Usuarios son completamente responsables del acceso y correcto uso de su perfil 
      y demás contenidos de la Plataforma con sujeción a la legalidad vigente, 
      sea nacional o internacional del País desde el que hace uso de la Plataforma, 
      así como a los principios de buena fe, a la moral, buenas costumbres y orden público. 
      Y específicamente, adquiere el compromiso de observar diligentemente las presentes 
      <strong>Condiciones Generales de Uso</strong>.
    </div>
      
    <div class="paragraph-primary mt-3">
      Los Usuarios son responsables de consignar correctamente nombres de Usuario y contraseñas individuales, 
      no transferibles y lo suficientemente complejas, 
      así como no usar el mismo nombre de Usuario y contraseña que en otras plataformas, 
      todo ello con la finalidad de proteger su cuenta del uso fraudulento 
      por parte de terceros ajenos a la Plataforma.
    </div>
      
    <div class="paragraph-primary mt-3">
      Los Usuarios se abstendrán de usar su perfil y el resto de los contenidos de la Plataforma 
      con fines o efectos ilícitos y que sean lesivos de los derechos e intereses de terceros, 
      o que de cualquier forma puedan dañar, inutilizar, afectar o deteriorar la Plataforma, 
      sus contenidos y sus servicios. 
      Asimismo, queda prohibido impedir la normal utilización o disfrute de la Plataforma a otros Usuarios.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no podrá ser considerada responsable editorial, y declara expresamente que  
      no se identifica con ninguna de las opiniones que puedan emitir los Usuarios de la Plataforma, 
      de cuyas consecuencias se hace enteramente responsable el emisor de las mismas.
    </div>
      
    <div class="paragraph-primary mt-3">
      Quienes incumplan tales obligaciones responderán de cualquier perjuicio o daño que ocasionen. 
      LOGIOK no responderá de ninguna consecuencia, daño o perjuicio que pudiera derivarse 
      de dicho acceso o uso ilícito por parte de terceros.
    </div>
      
    <div class="paragraph-primary mt-3">
      En general, los Usuarios se comprometen, a título enunciativo y no taxativo, a:
    </div>
      
    <ul class="ml-9 my-6">
      <li>
        No alterar o modificar, total o parcialmente la Plataforma, eludiendo, 
        desactivando o manipulando de cualquier otra las funciones o servicios de la misma;
      </li>
      <li>
        No infringir los derechos de propiedad industrial e intelectual 
        o las normas reguladoras de la protección de datos de carácter personal;
      </li>
      <li>
        No usar la Plataforma para injuriar, difamar, intimidar, violar la propia imagen o acosar a otros Usuarios; 
      </li>
      <li>
        No acceder a las cuentas de correo electrónico de otros Usuarios;
      </li>
      <li>
        No introducir virus informáticos, archivos defectuosos, o cualquier otro programa informático 
        que pueda provocar daños o alteraciones en los contenidos o sistemas de LOGIOK o terceras personas;
      </li>
      <li>
        No remitir correos electrónicos con carácter masivo y/o repetitivo a una pluralidad de personas, 
        ni enviar direcciones de correo electrónico de terceros sin su consentimiento;
      </li>
      <li>
        No realizar acciones publicitarias de bienes o servicios sin el previo consentimiento de LOGIOK).  
      </li>
    </ul>
      
    <div class="paragraph-primary mt-3">
      Cualquier Usuario podrá reportar a otro Usuario cuando considere que está incumpliendo 
      las presentes <strong>Condiciones Generales de Uso</strong>, 
      asimismo todos los Usuarios pueden informar a LOGIOK de cualquier abuso o vulneración 
      de las presentes condiciones, a través del Formulario de Contacto. 
      LOGIOK verificará este reporte, a la mayor brevedad posible, 
      y adoptará las medidas que considere oportunas, reservándose el derecho 
      a retirar y/o suspender a cualquier Usuario de la Plataforma 
      por el incumplimiento de las presentes <strong>Condiciones Generales de Uso</strong>. 
      Asimismo (LOGIOK) se reserva el derecho a retirar y/o suspender cualquier mensaje 
      con contenido ilegal u ofensivo, sin necesidad de previo aviso o posterior notificación.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      11.- Baja del Usuario
    </div>
      
    <div class="paragraph-primary">
      El Usuario podrá darse de baja de la Plataforma a través del 
      Formulario de Contacto dentro de la Plataforma.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      12.- Responsabilidad de LOGIOK
    </div>
      
    <div class="paragraph-primary">
      El Usuario es responsable de contar con los servicios y equipos necesarios 
      para la navegación por Internet y para acceder a la Plataforma. 
      En caso de cualquier incidencia o dificultad para acceder a la Plataforma, 
      el Usuario puede informarlo a LOGIOK a través de los canales de contacto 
      puestos a disposición del Usuario, que procederá a analizar la incidencia 
      y dará indicaciones al Usuario acerca de cómo resolverla en el plazo más breve posible.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no controla ni es responsable de los contenidos vertidos por los Usuarios a través de la Plataforma, 
      siendo estos el único responsable de la adecuación legal de dichos contenidos.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no responderá en caso de interrupciones del servicio, errores de conexión, 
      falta de disponibilidad o deficiencias en el servicio de acceso a Internet, 
      ni por interrupciones de la red de Internet o por cualquier otra razón ajena a su control.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no se hace responsable de los errores de seguridad que se puedan producir 
      ni de los daños que puedan causarse al sistema informático del Usuario (hardware y software), 
      a los ficheros o documentos almacenados en el mismo, como consecuencia de:
    </div>
      
    <ul class="ml-9 my-6">
      <li>
        La presencia de un virus en el sistema informático o terminal móvil del Usuario 
        que sea utilizado para la conexión a los servicios y contenidos de la Plataforma;
      </li>
      <li>
        Un mal funcionamiento del navegador;
      </li>
      <li>
        Del uso de versiones no actualizadas del mismo.
      </li>
    </ul>
    
    <div class="h2-primary mt-9 mb-6">
      13. Responsabilidad por contenidos
    </div>
      
    <div class="paragraph-primary">
      LOGIOK no tiene obligación de controlar y no controla la utilización 
      que los Usuarios hacen de la Plataforma y, por consiguiente, 
      no garantiza que los Usuarios utilicen la Plataforma de conformidad con lo establecido 
      en las presentes <strong>Condiciones Generales de Uso</strong>, 
      ni que hagan un uso diligente y/o prudente de la misma. 
      LOGIOK no tiene la obligación de verificar y no verifica la identidad de los Usuarios, 
      ni la veracidad, vigencia, exhaustividad y/o autenticidad de los datos que los mismos proporcionan.
    </div>
      
    <div class="paragraph-primary mt-3">
       LOGIOK no verificará los bienes objeto de transporte que puedan recogerse o enviarse 
       a través de los servicios solicitados a través de la Plataforma. 
       Por esta razón, tanto el Usuario como el proveedor eximen a LOGIOK de cualquier responsabilidad 
       que pudiera derivarse de la puesta a disposición y/o transporte de los bienes 
       que requieran permisos o licencias específicos, así como productos prohibidos 
       por cualquier normativa de aplicación. 
       En el supuesto que LOGIOK tenga conocimiento que el Usuario y/o el proveedor 
       utilizan la Plataforma para el transporte de productos prohibidos, 
       LOGIOK podrá iniciar una investigación interna para esclarecer los hechos, 
       interponer las medidas legales necesarias y/o bloquear al Usuario o proveedor 
       correspondiente hasta la finalización de la posible investigación. 
       Entre las medidas a disposición de LOGIOK se encuentra el poner a disposición 
       de las autoridades los datos e informaciones de los Usuarios y Mandatarios.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza 
      que pudieran deberse a la utilización ilícita de la Plataforma por parte de los Usuarios 
      o que puedan deberse a la falta de veracidad, vigencia, exhaustividad y/o autenticidad 
      de la información que los Usuarios proporcionan a otros Usuarios acerca de sí mismos y, 
      en particular, aunque no de forma exclusiva, por los daños y perjuicios de toda naturaleza 
      que puedan deberse a la suplantación de la personalidad de un tercero efectuada 
      por un Usuario en cualquier clase de comunicación realizada a través de la Plataforma. 
      En particular, LOGIOK no se hace responsable del uso de la Plataforma 
      así como de los servicios que pudiera realizar un tercero desde la cuenta del Usuario.
    </div>
      
    <div class="paragraph-primary mt-3">
      Sin perjuicio de lo anterior, LOGIOK se reserva la facultad de limitar, total o parcialmente, 
      el acceso a la Plataforma a determinados Usuarios, así como de cancelar, 
      suspender, bloquear o eliminar determinado tipo de contenidos, 
      mediante la utilización de instrumentos tecnológicos aptos al efecto, 
      si tuviese conocimiento efectivo o existiesen indicios de que la actividad 
      o la información almacenada es ilícita o de que lesiona bienes o derechos de un tercero. 
      En este sentido, LOGIOK podrá establecer los filtros necesarios a fin de evitar 
      que a través del servicio puedan verterse en la red contenidos ilícitos o nocivos. 
      La puesta a disposición por parte de los Usuarios de contenidos a través de la Plataforma 
      supondrá la cesión a favor de LOGIOK de todos los derechos de explotación derivados 
      de los contenidos suministrados en la Plataforma.
    </div>
    
    <div class="h2-primary mt-9 mb-6">
      14. Cláusula de "no garantía" en los recados ni en los pagos
    </div>
      
    <div class="paragraph-primary">
      LOGIOK no ofrece ninguna garantía con respecto a la autenticidad, la exactitud, 
      la novedad, la fiabilidad, legalidad o no violación de derechos de terceros 
      por parte de los proveedores. 
      En este sentido, los Usuarios manifiestan que comprenden que LOGIOK es una plataforma tecnológica, 
      cuya principal actividad es la intermediación, esto es, pone en contacto Usuarios y proveedores, 
      por lo que no asume responsabilidad alguna derivada de la información proporcionada 
      por los proveedores ni por los daños o pérdidas que eventualmente pudieran sufrir 
      por un incumplimiento de las presentes <strong>Condiciones Generales de Uso</strong>. 
      Al realizar el servicio a través de la Plataforma y su aceptación por parte del proveedor, 
      se establecerá una relación directa entre el Usuario y el Mandatario, 
      siendo LOGIOK ajena a la misma, facilitando únicamente la Plataforma 
      para que las Partes puedan comunicarse y realizar cuantos servicios sean necesarios. 
      Por ello LOGIOK no será nunca responsable ni de la disponibilidad de los proveedores 
      ni del cumplimiento adecuado y satisfactorio de los recados por parte de éstos.
    </div>
      
    <div class="paragraph-primary mt-3">
      LOGIOK no realiza confirmación alguna, ni valida ningún proveedor ni su identidad ni antecedentes. 
      Sin perjuicio de lo anterior, LOGIOK puede eventualmente realizar comprobaciones adicionales 
      y poner en marcha procedimientos destinados a ayudar a verificar o a comprobar las identidades 
      de los proveedores. 
      En este sentido, cuando un Usuario alcanza el estatus de proveedor, simplemente indica que dicho Usuario 
      ha completado el proceso de alta correspondiente y ha aceptado las presentes 
      <strong>Condiciones Generales de Uso</strong> y cualquier otra condición particular 
      que le resultara aplicable. 
      No implica ni certificación ni aval acerca de su fiabilidad, idoneidad y/o seguridad por parte de LOGIOK.
    </div>
      
    <div class="paragraph-primary mt-3">
      Por ello LOGIOK les recomienda a los Usuarios que empleen siempre el sentido común 
      y toda su atención a la hora de delegar un recado a un determinado proveedor.
    </div>
      
    <div class="paragraph-primary mt-3">
      Al ser miembros de la Plataforma, los Usuarios aceptan que cualquier responsabilidad legal 
      que pretendan ejercitar como consecuencia de acciones u omisiones de otros Usuarios de la Plataforma 
      o terceros se limitará a dichos Usuarios o terceros, no procediendo acción de responsabilidad 
      alguna contra LOGIOK.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      15. Actualización y Modificación de la Plataforma
    </div>
      
    <div class="paragraph-primary">
      LOGIOK se reserva el derecho de modificar, en cualquier momento y sin previo aviso, 
      las presentes <strong>Condiciones Generales de Uso</strong>, 
      la <router-link to="/legal/privacy"><strong><u>Política de Privacidad</u></strong></router-link> 
      y la <router-link to="/legal/cookies"><strong><u>Política de Cookies</u></strong></router-link>.  
      Los Usuarios deberán leer atentamente estas Condiciones Generales al acceder a la Plataforma. 
      En cualquier caso, la aceptación de las Condiciones Generales será un paso previo e indispensable 
      al acceso de los servicios y contenidos disponibles a través de la Plataforma LOGIOK.
    </div>
      
    <div class="paragraph-primary mt-3">
      Asimismo, LOGIOK. se reserva la facultad de efectuar, en cualquier momento 
      y sin necesidad de previo aviso, actualizaciones, modificaciones o eliminación de información 
      contenida en su Plataforma en la configuración y presentación de ésta y de las condiciones de acceso, 
      sin asumir responsabilidad alguna por ello. 
      LOGIOK no garantiza la inexistencia de interrupciones o errores en el acceso de la Plataforma 
      o a su contenido, ni que ésta se encuentre siempre actualizada, no obstante, 
      LOGIOK llevará a cabo, siempre que no concurran causas que lo hagan imposible o de difícil ejecución, 
      y tan pronto tenga noticia de los errores, desconexiones o falta de actualización en los contenidos, 
      todas aquellas labores tendentes a subsanar los errores, restablecer la comunicación 
      y actualizar los contenidos.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      16. Propiedad Intelectual
    </div>
      
    <div class="paragraph-primary">
      LOGIOK es titular o licenciataria de todos los derechos de propiedad intelectual e industrial 
      incluidos en la Plataforma así como sobre los contenidos accesibles a través de la misma. 
      Los derechos de propiedad intelectual de la Plataforma, así como: textos, imágenes, diseño gráfico, 
      estructura de navegación, información y contenidos que se recogen en ella son titularidad de LOGIOK, 
      a quien corresponde el ejercicio exclusivo de los derechos de explotación de los mismos 
      en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública 
      y transformación, de conformidad con la legislación española de derechos de propiedad intelectual 
      e industrial.
    </div>
      
    <div class="paragraph-primary mt-3">
       Aún cuanto, descrito anteriormente, LOGIOK  podría no ser titular ni licenciatario de contenidos 
       como nombres o imágenes, entre otros, de compañías con las cuales LOGIOK no tiene relación comercial. 
       En esos supuestos, LOGIOK adquiere los contenidos de fuentes accesibles al público 
       y en ningún caso se entenderá que LOGIOK tenga relación con ningún derecho titularidad de LOGIOK.
    </div>
      
    <div class="paragraph-primary mt-3">
      La autorización al Usuario para el acceso a la Plataforma no supone renuncia, transmisión, 
      licencia o cesión total ni parcial sobre derechos de propiedad intelectual o industrial 
      por parte de LOGIOK. 
      No está permitido suprimir, eludir o manipular de ningún modo los contenidos de la Plataforma de LOGIOK. 
      Asimismo, está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, 
      hacer segundas o posteriores publicaciones, cargar archivos, enviar por correo, transmitir, usar, 
      tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos 
      en la Plataforma de LOGIOK para propósitos públicos o comerciales, si no se cuenta 
      con la autorización expresa y por escrito de LOGIOK o, en su caso, del titular de los derechos 
      a que corresponda.
    </div>
      
    <div class="paragraph-primary mt-3">
      El Usuario que proceda a compartir cualquier tipo de contenido a través de la Plataforma, 
      asegura que ostenta los derechos necesarios para hacerlo, quedando exento LOGIOK 
      de cualquier responsabilidad sobre el contenido y legalidad de la información ofrecida. 
      La facilitación de contenidos por parte de los Usuarios a través de la Plataforma 
      supondrá la cesión a LOGIOK, con carácter gratuito, y con la máxima amplitud permitida 
      por la legislación vigente, de los derechos de explotación de propiedad intelectual o industrial 
      derivados de tales contenidos.
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      17. Independencia de las Cláusulas
    </div>
      
    <div class="paragraph-primary">
      Si cualquiera de las cláusulas de las presentes <strong>Condiciones Generales</strong>
      fuera nula de pleno derecho o anulable, se tendrá por no puesta. 
      Dicha declaración de nulidad no invalidará el resto de las cláusulas, 
      que mantendrá su vigencia y eficacia entre las Partes. 
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      18. Legislación Aplicable
    </div>
      
    <div class="paragraph-primary">
      La relación entre LOGIOK y el Usuario, se regirá e interpretará de conformidad con las 
      <strong>Condiciones Generales</strong> que en materia de interpretación, 
      validez y ejecución se regirán por la legislación española; 
      y cualquier controversia se someterá a los Tribunales de <strong>BARCELONA</strong>, 
      salvo que el Usuario solicite los tribunales de su domicilio de residencia. 
    </div>
      
    <div class="h2-primary mt-9 mb-6">
      19. Resolución extrajudicial de conflictos
    </div>
      
    <div class="paragraph-primary">
      Los conflictos o controversias que tuvieran lugar fuera de la Unión Europea 
      quedarán sujetos a su resolución por parte de la Corte Española de Arbitraje de la Cámara de Comercio.
    </div>
  </div>
  <!--end::Legal Terms ES-->
</template>

<script>
export default {
  name: "LegalTermsES"
};
</script>
