<template>
  <!--begin::Legal Terms-->
  <div>
    <LegalTermsEN v-if="UserLang === 'en'" />
    <LegalTermsES v-else />
  </div>
  <!--end::Legal Terms-->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import LegalTermsEN from "@/view/pages/landing/legal/terms/Content_en.vue";
import LegalTermsES from "@/view/pages/landing/legal/terms/Content_es.vue";

export default {
  name: "LegalTerms",

  components: {
    LegalTermsEN,
    LegalTermsES
  },

  data() {
    return {
      UserLang: i18nService.getActiveLanguage()
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE");
    });
  }
};
</script>
